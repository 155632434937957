<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit-qr-code'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", "edit-resource", "qr-code") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section"
      v-if="resource && resource.data && resource.data.qrcode"
    >
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <div class="qr-code">
              <img :src="resource.data.qrcode" alt="Qr code" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <section class="clean-footer-menu icon-foo1">
        <nav
          class="actions-menu theme-gray"
          v-if="resource && resource.data && resource.data.qrcode"
        >
          <ul class="actions-list">
            <li class="action">
              <button
                class="action-btn active"
                @click="regenerateQrCode(resource.data.id)"
              >
                {{ displayLabelName("resources.resources.regenerate-qr-code") }}
              </button>
            </li>
          </ul>
        </nav>
        <nav class="menu">
          <ul class="menu-items">
            <li
              class="menu-item central-item icon-foo2"
              style="position: relative; top: -50px;"
            >
              <qr-code-scanner-button />
            </li>
          </ul>
        </nav>
      </section>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";

export default {
  name: "EditResourceQrCode",
  mixins: [helpOnlineMixin],
  data() {
    return {
      helpSlug: "resources-qr-code"
    };
  },
  computed: {
    ...mapState("resource", ["resource"])
  },
  mounted() {
    if (!this.resource) {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  methods: {
    async regenerateQrCode(id) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.resources}/${id}/qr-regenerate`)
        .then(() => {
          this.$store.dispatch(
            "resource/getResource",
            { id: id },
            { root: true }
          );
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    QrCodeScannerButton
  }
};
</script>
